import React, { useEffect, useState } from "react";

import { Alert, Col, Input, InputGroup, Label, Row } from "reactstrap";

import withRouter from "../../hooks/withRouter";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { getWithoutToken, postWithoutToken } from "../../helpers/axios_with_headers.js";
import { phoneCodes, phoneOptionsTurkish } from "../../constants/phoneCodesTurkish";
import ReCAPTCHA from "react-google-recaptcha";
import { phoneOptions } from "../../constants/phoneCodes";
import Select from "react-select";
import axios from "axios";
import {formatDate, formatDateScreen, formatTimeScreen, StringToDate} from "../../common/commonFunctions";

// import images

const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
const WebFormAppointmentTr = props => {

  //meta title
  document.title = "Web Form";

  const [fullName, setFullName] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("09:00");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState(null);
  const [responseCode, setResponseCode] = useState(null);
  const [phoneCode, setPhoneCode] = useState(phoneOptions[0]);
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [disableButton, setIsDisableButton] = useState(true);
  const [sendCodeButtonDisabled, setSendCodeButtonDisabled] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [clinicOptions, setClinicOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [dateArray, setDateArray] = useState([]);
  const [timeArray, setTimeArray] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedTime, setSelectedTime] = useState([]);
  const [isContractChecked, setIsContractChecked] = useState(false);
  const [isPermissionCheck, setIsPermissionCheck] = useState(false);
  const [phoneCodeTurkish, setPhoneCodeTurkish] = useState(true);

  const recaptchaRef = React.createRef();

  const handleChange = (e) => {
    switch (e.target.name) {
      case "fullName":
        setFullName(e.target.value);
        break;
      case "date":
        setDate(e.target.value);
        break;
      case "time":
        setTime(e.target.value);
        break;
      case "code":
        setCode(e.target.value);
        break;
    }
  };

  useEffect(() => {
    getWithoutToken(process.env.REACT_APP_API_URL + "/userGroup/findHighAuthUsersWithDepartment").then(response => {
      if (response.status === 200) {
        const sectionOptions = response.data.length > 0 && response.data.map(item => ({
          label: item.section,
          value: item
        }));
        setSectionOptions(sectionOptions);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedSection && selectedSection.value) {
      const clinicList = selectedSection && selectedSection.value.departments && selectedSection.value.departments.map(item => ({
        label: item.departmentName,
        value: item
      }));
      setClinicOptions(clinicList);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (selectedClinic && selectedClinic.value) {
      const doctorList = selectedClinic && selectedClinic.value.users && selectedClinic.value.users.map((doctor) => ({
        label: doctor.fullName,
        value: doctor.id
      }));
      setDoctorOptions(doctorList);
    }
  }, [selectedClinic]);

  useEffect(() => {
    const doctorList = selectedClinic && selectedClinic.value.users && selectedClinic.value.users.map((doctor) => ({
      label: doctor.fullName,
      value: doctor.id
    }));
    setDoctorOptions(doctorList || []);
  }, [selectedClinic]);

  const onChangePhoneCode = (e) => {
    if (e&&e.target.value!=="+90"){
      setPhoneCodeTurkish(false);
    }else{
      setPhoneCodeTurkish(true);
    }
    setPhoneCode({ label: e.target.label, value: e.target.value });
  };

  const handleChangePhone = (e) => {
    let str = e.target.value.toString();
    let phoneCode = "";
    let tel = "";
    if (str.includes("+")) {
      phoneCode = str.split(/\s/)[0];
      findPhoneCode(phoneCode);
      tel = str.split(phoneCode)[1].replaceAll(/\s+/g, "");
    } else {
      tel = str.replaceAll(/\s+/g, "");
    }
    setPhoneNumber(tel);
  };

  const findPhoneCode = (code) => {
    phoneOptions.forEach(cd => {
      if (cd.value === code)
        setPhoneCode(cd);
    });
  };

  useEffect(() => {
    const handle = setInterval(() => {
      setSendCodeButtonDisabled(false);
    }, 60000);
    return () => {
      clearInterval(handle);
    };
  }, []);

  useEffect(() => {
    const dis = isCaptchaSuccessful && fullName !== "" && phoneNumber !== "" && date !== "" && time !== "" &&  isContractChecked === true && isPermissionCheck === true;
    setIsDisableButton(!dis);
  }, [isCaptchaSuccessful, fullName, phoneNumber, isPermissionCheck, isContractChecked]);

  const sendFormDataToBackend = () => {
    const createLeadReq = {
      secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
      fullName: fullName,
      phoneNumber: phoneCode.value + phoneNumber,
      appointmentDate: formatDate(StringToDate(date + " " + time, "DDMMYYYY", "-")),
      appointmentUserId: selectedDoctor.value
    };

    postWithoutToken(process.env.REACT_APP_API_URL + "/leadIntegration/receivePotentialPatientFromWebsite", createLeadReq).then(r => {
      if (r.status === 200) {
        setIsSent(true);
        setPhoneNumber("");
        setFullName("");
        setAppointmentDate("");
        setPhoneCode(phoneCodes[0]);
        setIsCaptchaSuccess(false);
        setIsDisableButton(true);
        recaptchaRef.current.reset();
      }
    });
  };

  const getAppointmentDataFromBackend = (id) => {
    const getReq = {
      secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
      doctorId: id
    };

    postWithoutToken(process.env.REACT_APP_API_URL + "/leadIntegration/findAvailableTimes", getReq).then(response => {
      if (response.status === 200 && response.data) {
        setDateArray(response.data.map((event) => ({ label: formatDateScreen(event.date), value: event })));
      }
    });
  };

  function onCaptchaChange(value) {
    setIsCaptchaSuccess(true);
  }

  function onChangeSection(event) {
    if (event !== null) {
      setSelectedSection(event);
    } else {
      setSelectedSection(null);
      setSelectedClinic(null);
      setSelectedDoctor(null);
      setDateArray([]);
      setTimeArray([]);
    }
  }

  function onChangeClinic(event) {
    if (event !== null) {
      setSelectedClinic(event);
    } else {
      setSelectedClinic(null);
      setSelectedDoctor(null);
      setDateArray([]);
      setTimeArray([]);
    }
  }

  function onChangeDoctor(event) {
    if (event !== null) {
      setSelectedDoctor(event);
      getAppointmentDataFromBackend(event.value);
    } else {
      setSelectedDoctor(null);
      setDateArray([]);
      setTimeArray([]);
    }
  }

  function sendCodeToPhone() {
    setSendCodeButtonDisabled(true);
    axios.get(process.env.REACT_APP_API_URL + "/sms/getSMSVerificationCode/" + phoneCode.value.replace("+", "") + phoneNumber).then(r => {
      if (r.status === 200 && r.data !== null) {
        setResponseCode(r.data.toString());
      }
    });
  }

  function onChangeDate(e) {
    setSelectedDate(e);
    setDate(e && e.value ? e.value.date : null);
    setSelectedTime(null);
    const timeOptions = e && e.value && e.value.hours.map((hour) => ({ label: formatTimeScreen(hour), value: hour }));
    setTimeArray(timeOptions);
  }

  function onChangeTime(e) {
    setSelectedTime(e);
    setTime(e && e.value ? e.value : null);
  }


  return (
      <div className="page-content-centered">
        <div className="vertical-centered-div">
          {!isSent ? (
              <Col xl={12} md={12} xs={12}>
                <Row className="m-2">
                  <div className="mb-2">
                    <Label className="form-label">{"İsim"}</Label>
                    <Input
                        id="fullName"
                        name="fullName"
                        type="text"
                        onChange={handleChange}
                        value={fullName || ""}
                        invalid={!fullName}
                        required={true}
                    />
                  </div>
                  <div className="mb-2">
                    <Label className="form-label">{props.t("Telefon")}</Label>
                    <InputGroup>
                      <select
                          id="phoneCode"
                          className="form-select"
                          value={phoneCode.value}
                          onChange={onChangePhoneCode}
                      >
                        {phoneOptionsTurkish.map(code => (
                            <option id={code.value} key={code.label} value={code.value}>
                              {code.label}
                            </option>
                        ))
                        }
                      </select>
                      <Input
                          id="phoneNum"
                          name="phoneNumber"
                          type="text"
                          onChange={handleChangePhone}
                          value={phoneNumber || ""}
                          required={true}
                          placeholder="--- --- ----"
                          invalid={!phoneNumber}
                      />
                      {phoneCodeTurkish &&(
                          <button
                              disabled={phoneNumber.length < 5 || phoneCode.value === undefined || sendCodeButtonDisabled}
                              id="sendCode"
                              type="submit"
                              className="btn btn-outline-warning"
                              onClick={() => sendCodeToPhone()}
                          >
                            {props.t("Kod Gönder")}
                          </button>
                      )}
                    </InputGroup>
                  </div>
                  {phoneCodeTurkish &&(
                      <div className="mb-2">
                        {code !== null && code !== responseCode && (
                            <Alert color="danger" style={{ marginTop: "3px" }}>SMS ile gönderilen doğrulama kodu ile girilen kod
                              eşleşmiyor</Alert>)}
                        <Label className="form-label">{props.t("Doğrulama Kodu")}</Label>
                        <Input
                            id="code"
                            name="code"
                            type="code"
                            onChange={handleChange}
                            value={code || ""}
                            required={true}
                            invalid={code === null || code !== responseCode}
                        />
                      </div>
                  )}
                  <div className="mb-2">
                    <Label className="form-label">{"Muayenehane"}</Label>
                    <Select
                        value={selectedSection}
                        onChange={(event) => onChangeSection(event)}
                        options={sectionOptions}
                        className="bs-select"
                        isClearable={true}
                    />
                  </div>
                  <div className="mb-2">
                    <Label className="form-label">{"Sağlık Ofisi"}</Label>
                    <Select
                        value={selectedClinic}
                        onChange={onChangeClinic}
                        options={clinicOptions}
                        className="bs-select"
                        isClearable={true}
                    />
                  </div>
                  <div className="mb-2">
                    <Label className="form-label">{"Uzman"}</Label>
                    <Select
                        value={selectedDoctor}
                        onChange={onChangeDoctor}
                        options={doctorOptions}
                        className="bs-select"
                        isClearable={true}
                    />
                  </div>
                </Row>
                <Row className="m-2 row-cols-2">
                  <div className="col-xs-6 col-sm-6 col-md-6 mb-3">
                    <Label className="form-label">Tarih</Label>
                    <Select
                        id="date"
                        className="bs-select"
                        value={selectedDate}
                        onChange={(e) => onChangeDate(e)}
                        options={dateArray}
                        isClearable={true}
                    />
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6 mb-3">
                    <Label className="form-label">Saat</Label>
                    <Select
                        id="representHistory"
                        className="bs-select"
                        value={selectedTime}
                        onChange={(e) => onChangeTime(e)}
                        options={timeArray}
                        isClearable={true}
                    />
                  </div>
                </Row>
                <Row className="ms-3 me-3 mt-2 mb-2 border border-1">
                  <Row className="ms-0 me-0 mt-3 mb-3 justify-content-center">
                    <div className="col text-center">
                      <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="contractCheck"
                          checked={isContractChecked}
                          onChange={() => {
                            setIsContractChecked(!isContractChecked);
                          }}
                      />
                      <a
                          href="http://meditown.com.tr/wp-content/uploads/2024/02/Sartlar-ve-Kosullar-TR.pdf"
                          target="_blank" rel="noreferrer"
                          download><span className="fw-bold">KVKK Aydınlatma Metnini </span></a> okudum, anladım ve
                      onaylıyorum.
                    </div>
                  </Row>
                </Row>
                <Row className="ms-3 me-3 mt-2 mb-2 border border-1">
                  <Row className="ms-0 me-0 mt-3 mb-3 justify-content-center">
                    <div className="col text-center">
                      <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="contractPermissionCheck"
                          checked={isPermissionCheck}
                          onChange={() => {
                            setIsPermissionCheck(!isPermissionCheck);
                          }}
                      />
                      Meditown Sağlık Turizm A.Ş.’nin tarafıma Ticari Elektronik İleti (SMS, arama, mail vb.) göndermesine
                      <a
                          href="http://meditown.com.tr/wp-content/uploads/2024/02/Ticari-Elektronik-Ileti-Izni-Onayi-TR.pdf"
                          target="_blank" rel="noreferrer"
                          download><span className="fw-bold"> burada belirtilen izni </span></a> veriyorum.
                    </div>
                  </Row>
                </Row>
                <Row className="m-2">
                  <Col className="col"></Col>
                  <Col className="col">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        name="recaptcha"
                        id="recaptcha"
                        sitekey={siteKey}
                        onChange={onCaptchaChange}
                        onExpired={() => {
                          recaptchaRef.current.reset(); // here
                        }}
                        theme="light"
                    />
                  </Col>
                  <Col className="col"></Col>
                </Row>
                <Row className="m-2">
                  <Col>
                    <div className="text-end mt-2">
                      <button
                          disabled={disableButton || phoneCodeTurkish && ( code === null || code !== responseCode) }
                          id="save"
                          type="submit"
                          className="btn btn-success"
                          onClick={() => sendFormDataToBackend()}
                      >
                        {"Kaydet"}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>) : (
              <div>
                <p className="text-sm-center">
                  <i className="mdi mdi-check-bold text-success display-1" />
                  <p className="text-info font-size-24 fw-bold">Teşekkürler</p>
                  <p className="text-dark font-size-16 fw-bold mt-2">Başvurunuzu aldık.</p>
                  <p className="text-secondary font-size-8 mt-0">Mümkün olan en kısa sürede sizlere geri dönüş
                    sağlayacağız.</p>
                </p>
              </div>
          )}
        </div>
      </div>
  );
};

WebFormAppointmentTr.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(WebFormAppointmentTr));