import React from "react";

// //Leads
import Leads from "../pages/Leads";
import LeadImport from "../pages/Leads/LeadImport";
import LeadDetail from "../pages/Leads/LeadDetail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//  // Inner Authentication
//Users
import UsersList from "../pages/Users/UserList/users-list";
import UsersProfile from "../pages/Users/UsersProfile/users-profile";
import Segment from "../pages/Segment";
import Category from "../pages/Category";
import Resource from "../pages/Resource";
import Reference from "../pages/Reference";
import Dashboard from "../pages/Dashboard";
import Appointment from "../pages/Appointment";
import Reminder from "../pages/Reminder";
import AdminDashboard from "../pages/AdminDashboard";
import Report from "../pages/Report";
import ReportList from "../pages/Report/ReportList";
import IntegrationDefinition from "../pages/IntegrationDefinition";
import PrivacyPolicy from "../pages/templates/PrivacyPolicy";
import TermsAndConditions from "../pages/templates/TermsAndConditions";
import Integration from "../pages/Integration";
import SystemParameter from "../pages/SystemParameter";
import LeadHistory from "../pages/LeadHistory";
import FacebookForm from "../pages/FacebookForm";
import Languages from "../pages/Languages";
import FacebookLoginHistory from "../pages/FacebookLoginHistory";
import UserLoginHistory from "../pages/UserLoginHistory";
import LeadIntegrationDefinition from "../pages/LeadIntegrationDefinition";
import Hospital from "../pages/Hospital";
import Doctor from "../pages/Doctor";
import OperationCategory from "../pages/OperationCategory";
import OperationDefinition from "../pages/OperationDefinition";
import OfferItem from "../pages/OfferItem";
import Offer from "../pages/Offer";
import MedicalOperation from "../pages/MedicalOperation";
import DescriptionTemplate from "../pages/DescriptionTemplate";
import Pages500 from "../pages/Utility/Pages500";
import Pages404 from "../pages/Utility/Pages404";
import Payment from "../pages/Payment";
import FirmInfo from "../pages/FirmInfo";
import WebFormVerified from "../pages/WebFormVerified";
import WebFormEn from "../pages/WebForm/en";
import WebFormTr from "../pages/WebForm/tr";
import WebFormRu from "../pages/WebForm/ru";
import WebFormPt from "../pages/WebForm/pt";
import WebFormDe from "../pages/WebForm/de";
import WebFormFr from "../pages/WebForm/fr";
import Guard from "../pages/Guard";
import PaymentGuard from "../pages/PaymentGuard";
import Group from "../pages/Group";
import Patients from "../pages/Patients";
import {Navigate} from "react-router-dom";
import Privilege from "../pages/Privilege";
import Department from "../pages/Department";
import FormSuccessTr from "../pages/WebForm/formSuccessTr";
import FormSuccessFr from "../pages/WebForm/formSuccessFr";
import FormSuccessDe from "../pages/WebForm/formSuccessDe";
import FormSuccessEn from "../pages/WebForm/formSuccessEn";

import PatientAppointment from "../pages/PatientAppointment";
import DoctorDashboard from "../pages/DoctorDashboard";
import WebFormAppointmentEn from "../pages/WebFormAppointment/en";
import WebFormAppointmentTr from "../pages/WebFormAppointment/tr";
import Calendar from "../components/Calendar";
import BlockScreen from "../pages/Utility/BlockScreen";
import NonAuthLayoutWithLanguage from "../components/NonAuthLayoutWithLanguage";
import WebFormAppointmentRu from "../pages/WebFormAppointment/ru";
import WebFormAppointmentAz from "../pages/WebFormAppointment/az";
import WebFormAppointmentAr from "../pages/WebFormAppointment/ar";
import Section from "../pages/Section";

const dashboard = localStorage.getItem("dashboard")
const authProtectedRoutes = [
    {path: "/dashboard", component: <Dashboard/>},
    {path: "/doctor-dashboard", component: <DoctorDashboard/>},
    {path: "/admin-dashboard", component: <AdminDashboard/>},
    {path: "/leads", component: <Leads/>},
    {path: "/lead-import", component: <LeadImport/>},
    {path: "/lead-detail", component: <LeadDetail/>},
    {path: "/segments", component: <Segment/>},
    {path: "/categories", component: <Category/>},
    {path: "/resources", component: <Resource/>},
    {path: "/references", component: <Reference/>},
    {path: "/forms", component: <FacebookForm/>},
    {path: "/integration", component: <Integration/>},
    {path: "/integration-definition", component: <IntegrationDefinition/>},
    {path: "/lead-integration-definition", component: <LeadIntegrationDefinition/>},
    {path: "/users-list", component: <UsersList/>},
    {path: "/users-profile", component: <UsersProfile/>},
    {path: "/appointments", component: <Appointment/>},
    {path: "/reminders", component: <Reminder/>},
    {path: "/reminders/:ntf", component: <Reminder/>},
    {path: "/leads-with-segment/:segmentId", component: <Leads/>},
    {path: "/leads-with-resource/:resourceId", component: <Leads/>},
    {path: "/reports", component: <Report/>},
    {path: "/report-list", component: <ReportList/>},
    {path: "/system-parameter", component: <SystemParameter/>},
    {path: "/languages", component: <Languages/>},
    {path: "/lead-history", component: <LeadHistory/>},
    {path: "/facebook-login-history", component: <FacebookLoginHistory/>},
    {path: "/user-login-history", component: <UserLoginHistory/>},
    {path: "/hospital", component: <Hospital/>},
    {path: "/doctor", component: <Doctor/>},
    {path: "/operation-category", component: <OperationCategory/>},
    {path: "/operation-definition", component: <OperationDefinition/>},
    {path: "/medical-operation", component: <MedicalOperation/>},
    {path: "/offer-item", component: <OfferItem/>},
    {path: "/offer", component: <Offer/>},
    {path: "/description-template", component: <DescriptionTemplate/>},
    {path: "/firm-info", component: <FirmInfo/>},
    {path: "/group", component: <Group/>},
    {path: "/patients", component: <Patients/>},
    {path: "/calendar", component: <Calendar/>},
    {path: "/privileges", component: <Privilege/>},
    {path: "/department", component: <Department/>},
    {path: "/section", component: <Section/>},
    {path: "/patient-appointment", component: <PatientAppointment/>},
    {path: "/payment-list", component: <Payment/>},
    {path: "/payment", component: <PaymentGuard/>},
    {path: "/privacy-policy", component: <PrivacyPolicy/>},
    {path: "/terms-and-conditions", component: <TermsAndConditions/>},
    {path: "/500", component: <Pages500/>},
    {path: "/404", component: <Pages404/>},
    {path: "/", exact: true, component: <Navigate to={dashboard ? dashboard : ""}/>}
];

const publicRoutes = [
    {path: "/logout", component: <Logout/>},
    {path: "/login", component: <Login/>, layout: <NonAuthLayoutWithLanguage/>},
    {path: "/web-form-verify", component: <WebFormVerified/>},
    {path: "/web-form/tr", component: <WebFormTr/>},
    {path: "/web-form/en", component: <WebFormEn/>},
    {path: "/web-form/de", component: <WebFormDe/>},
    {path: "/web-form/fr", component: <WebFormFr/>},
    {path: "/web-form/ru", component: <WebFormRu/>},
    {path: "/web-form/pt", component: <WebFormPt/>},
    {path: "/form-success/tr", component: <FormSuccessTr/>},
    {path: "/form-success/fr", component: <FormSuccessFr/>},
    {path: "/form-success/de", component: <FormSuccessDe/>},
    {path: "/form-success/en", component: <FormSuccessEn/>},
    {path: "/web-form-appointment/en", component: <WebFormAppointmentEn/>},
    {path: "/web-form-appointment/tr", component: <WebFormAppointmentTr/>},
    {path: "/web-form-appointment/ru", component: <WebFormAppointmentRu/>},
    {path: "/web-form-appointment/az", component: <WebFormAppointmentAz/>},
    {path: "/web-form-appointment/ar", component: <WebFormAppointmentAr/>},
    {path: "/privacy-policy", component: <PrivacyPolicy/>},
    {path: "/terms-and-conditions", component: <TermsAndConditions/>},
    {path: "/500", component: <Pages500/>, layout: <NonAuthLayoutWithLanguage/>},
    {path: "/404", component: <Pages404/>, layout: <NonAuthLayoutWithLanguage/>}
];

const privateRoutes = [
    {path: "/locked", component: <BlockScreen/>, layout: <NonAuthLayoutWithLanguage/>},
    {path: "/guard-payment", component: <PaymentGuard/>, layout: <NonAuthLayoutWithLanguage/>},
    {path: "/guard", component: <Guard/>, layout: <NonAuthLayoutWithLanguage/>}
];

export {authProtectedRoutes, publicRoutes, privateRoutes};
