export const apptTypeClass = {
    "APPROVED": "bg-success bg-soft text-dark",
    "REJECTED": "bg-danger bg-soft text-dark",
    "WAITING": "bg-warning bg-soft text-dark",
    "LEAVE": "bg-secondary bg-soft text-dark",
    "CANCELED": "bg-primary bg-soft text-dark",
    "COMPLETED": "bg-info bg-soft text-dark",
};

export const apptStatusActionClass = {
    "LEAVE": "rounded-pill badge-soft-secondary me-1",
    "APPROVED": "rounded-pill badge-soft-success me-1",
    "REJECTED": "rounded-pill badge-soft-danger me-1",
    "WAITING": "rounded-pill badge-soft-warning me-1",
    "CANCELED": "rounded-pill badge-soft-primary me-1",
    "COMPLETED": "rounded-pill badge-soft-info me-1",
};