import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import {Card, CardBody, Col, Collapse, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timegridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import "@fullcalendar/bootstrap/main.css";

//redux
import {useDispatch, useSelector} from "react-redux";
import {convertMinutesToTime, DateToString, formatDate} from "../../common/commonFunctions";
import {withTranslation} from "react-i18next";
import Select from "react-select";
import {get, getWithoutToken, post} from "../../helpers/axios_with_headers";
import * as url from "../../helpers/url_helper";
import WarningModal from "../Common/WarningModal";
import {Link} from "react-router-dom";
import {getUserWorkScheduleById} from "../../store/userWorkSchedule/actions";
import {apptStatusActionClass, apptTypeClass} from "./constant";
import useAuth from "../../hooks/useAuth";

const Calendar = props => {

    const dispatch = useDispatch();
    const {authUser} = useAuth();

    const locale = localStorage.getItem("I18N_LANGUAGE").toString();

    const now = new Date();
    const minus10day = new Date(now.getFullYear(),
        now.getMonth(),
        now.getDay() - 10);

    const plus2month = new Date(now.getFullYear(),
        now.getMonth() + 3,
        now.getDay());

    const patientAppointmentBody = {
        size: 100,
        page: 0,
        appointmentDateStart: DateToString(minus10day, "YYYY-MM-DD", "-"),
        appointmentDateEnd: DateToString(plus2month, "YYYY-MM-DD", "-")
    };

    //CONSTANTS
    const workDayOptions = [
        {label: props.t("Monday"), value: 1, name: "MONDAY"},
        {label: props.t("Tuesday"), value: 2, name: "TUESDAY"},
        {label: props.t("Wednesday"), value: 3, name: "WEDNESDAY"},
        {label: props.t("Thursday"), value: 4, name: "THURSDAY"},
        {label: props.t("Friday"), value: 5, name: "FRIDAY"},
        {label: props.t("Saturday"), value: 6, name: "SATURDAY"},
        {label: props.t("Sunday"), value: 0, name: "SUNDAY"}
    ];

    const [eventDetail, setEventDetail] = useState({});
    const [slotDuration, setSlotDuration] = useState("00:30");
    const [slotMinTime, setSlotMinTime] = useState("09:00");
    const [slotMaxTime, setSlotMaxTime] = useState("20:00");
    const [disableTimes, setDisableTimes] = useState({});
    const [hiddenDays, setHiddenDays] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedDay, setSelectedDay] = useState("");
    const [selectedDayEnd, setSelectedDayEnd] = useState("");
    const [events, setEvents] = useState([]);
    const [clinicOptions, setClinicOptions] = useState([]);
    const [doctorOptions, setDoctorOptions] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState();
    const [selectedCalendarClinic, setSelectedCalendarClinic] = useState();
    const [selectedCalendarSection, setSelectedCalendarSection] = useState();
    const [selectedDoctor, setSelectedDoctor] = useState();
    const [selectedCalendarDoctor, setSelectedCalendarDoctor] = useState();
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [patientAppointments, setPatientAppointments] = useState([]);
    const [userAvailability, setUserAvailability] = useState([]);
    const [warningModal, setWarningModal] = useState(false);
    const [selectedWorkdays, setSelectedWorkdays] = useState();
    const [apptSettingsOpen, setIsApptSettingsOpen] = useState(false);
    const [userWorkSchedule, setUserWorkSchedule] = useState({});
    const [sectionOptions, setSectionOptions] = useState([]);
    const [selectedSection, setSelectedSection] = useState();
    const [userAssistant, setUserAssistant] = useState(false);
    const [apptEvents, setApptEvents] = useState([]);
    const [leaveEvents, setLeaveEvents] = useState([]);
    const [sectionDepartmentClinicResponse, setSectionDepartmentClinicResponse] = useState([]);
    const [roleDoctor, setRoleDoctor] = useState(false);

    useEffect(() => {
        setRoleDoctor(authUser && authUser.role && authUser.role.name === "ROLE_DOCTOR")
    }, [authUser])

    useEffect(() => {
        setEvents(props.events);
    }, [props.events]);

    function checkOverlap(selected) {
        const existingEvents = events;
        const newEvent = selected;
        for (let i = 0; i < existingEvents.length; i++) {
            const existingStart = new Date(existingEvents[i].start);
            const existingEnd = new Date(existingEvents[i].end);
            const newStart = new Date(newEvent.start);
            const newEnd = new Date(newEvent.end);

            if ((newStart > existingStart && newStart < existingEnd) ||
                (newEnd > existingStart && newEnd < existingEnd) ||
                (newStart < existingEnd && existingStart < newEnd) ||
                (newStart < existingStart && newEnd > existingEnd) ||
                (newEnd.getTime() === existingStart.getTime() - 60000) ||
                (newStart.getTime() === existingEnd.getTime() - 60000)) {
                setWarningModal(true);
                return false;
            }
        }
        return true;
    }

    const toggle = () => {
        if (modal) {
            setModal(false);
            setEventDetail({});
            setSelectedDay("");
            setSelectedDayEnd("");
            setSelectedDoctor("");
            setFullName("");
            setPhoneNumber("");
            setSelectedClinic("");
            setSelectedSection("");
            setIsEdit(false);
        } else {
            setModal(true);
        }
    };

    const handleEventClick = arg => {
        setIsEdit(true);
        const event = arg.event;
        const filtered = events.filter(item => item.id && item.id.toString() === event._def.publicId)[0];
        if (props.eventComponent && props.external) {
            props.callbackEventClick(filtered);
            toggle();
        }
        setEventDetail({
            id: filtered.id,
            description: filtered.description,
            appointmentUser: filtered.appointmentUser,
            patient: filtered.patient,
            start: formatDate(filtered.start),
            end: formatDate(filtered.end),
            appointmentDate: formatDate(filtered.start)
        });
        setSelectedDay(formatDate(filtered.start));
        setSelectedDayEnd(formatDate(filtered.end));
        setPhoneNumber(filtered.patient && filtered.patient.phoneNumber || "");
        setFullName(filtered.patient && filtered.patient.fullName);
        if (selectedCalendarSection) {
            setSelectedSection(selectedCalendarSection);
        }
        if (selectedCalendarClinic) {
            setSelectedClinic(selectedCalendarClinic);
        }
        setSelectedDoctor({
            label: filtered.appointmentUser && filtered.appointmentUser.fullName,
            value: filtered.appointmentUser
        });
        toggle();
    };

    useEffect(() => {
        if (patientAppointments) {
            const mapApptData = async () => {
                const result = patientAppointments.map((item) => {
                    return ({
                        id: item.id,
                        title: item.patient.fullName,
                        fullName: item.patient.fullName,
                        phoneNumber: item.patient && item.patient.phoneNumber || "",
                        patient: item.patient,
                        appointmentUser: item.appointmentUser,
                        appointmentUserId: item.appointmentUser.id,
                        start: new Date(item.appointmentDate),
                        end: new Date(item.appointmentEndDate),
                        className: apptTypeClass[item.status]
                    })
                });
                setApptEvents(result)
            }
            mapApptData();
        }
    }, [patientAppointments]);

    useEffect(() => {
        if (userAvailability) {
            const mapAvailabilityData = async () => {
                const result = userAvailability.map((item) => {
                    return ({
                        id: item.id,
                        type: "LEAVE",
                        title: props.t("Leave"),
                        start: new Date(item.startTime),
                        end: new Date(item.endTime),
                        className: apptTypeClass["LEAVE"]
                    })
                });
                setLeaveEvents(result)
            }
            mapAvailabilityData();
        }
    }, [userAvailability]);

    useEffect(() => {
        setEvents([...leaveEvents, ...apptEvents]);
    }, [leaveEvents, apptEvents]);

    function findAndSetOtherInfos() {
        console.log("sectionDepartmentClinicResponse,", sectionDepartmentClinicResponse)
        const matching = sectionDepartmentClinicResponse.filter((item) => item.userId === authUser.id);
        console.log("matching.length", matching.length)
        if (matching.length === 0) { // matching.length === 0 asistan demek
            setUserAssistant(true);
        }
        const sectionOption = sectionDepartmentClinicResponse.map((item) => ({
            label: item.section,
            value: item.section
        }));
        setSelectedSection(sectionOption[0])
        setSelectedCalendarSection(sectionOption[0])
        setSectionOptions([sectionOption[0]]);

        const clinicOption = sectionDepartmentClinicResponse.map((item) => ({
            label: item.department,
            value: item.department
        }));
        setSelectedClinic(clinicOption[0])
        setSelectedCalendarClinic(clinicOption[0])
        setClinicOptions([clinicOption[0]]);

        const doctorOption = sectionDepartmentClinicResponse.map((item) => ({label: item.fullName, value: item.userId}))
        setSelectedDoctor(doctorOption[0])
        setSelectedCalendarDoctor(doctorOption[0])
        setDoctorOptions(doctorOption)

        if (props.callbackSelectedDoctor) {
            props.callbackSelectedDoctor(doctorOption[0].value);
        }
    }

    useEffect(() => {
        if (!roleDoctor) {
            if (selectedSection && selectedSection.value) {
                const clinicList = selectedSection.value.departments.map(item => ({
                    label: item.departmentName,
                    value: item
                }));
                setClinicOptions(clinicList);
            }
            if (selectedCalendarSection && selectedCalendarSection.value) {
                const clinicList = selectedCalendarSection.value.departments.map(item => ({
                    label: item.departmentName,
                    value: item
                }));
                setClinicOptions(clinicList);
            }
        }
    }, [selectedSection, selectedCalendarSection]);

    useEffect(() => {
        if (!roleDoctor) {
            if (selectedClinic && selectedClinic.value) {
                const doctorList = selectedClinic.value.users.map(item => ({
                    label: item.fullName,
                    value: item.id
                }));
                setDoctorOptions(doctorList);
            }
            if (selectedCalendarClinic && selectedCalendarClinic.value) {
                const doctorList = selectedCalendarClinic.value.users.map(item => ({
                    label: item.fullName,
                    value: item.id
                }));
                setDoctorOptions(doctorList);
            }
        }
    }, [selectedClinic, selectedCalendarClinic]);

    useEffect(() => {
        get(`${url.FIND_USERS_SAME_GROUP}/${authUser.id}`).then(response => setSectionDepartmentClinicResponse(response.data));
    }, [])

    useEffect(() => {
        if (roleDoctor && sectionDepartmentClinicResponse) {
            findAndSetOtherInfos();
        }
    }, [props.dashboard, props.external, authUser, sectionDepartmentClinicResponse]);

    useEffect(() => {
        if (props.callbackCloseModal) {
            toggle();
        }
    }, [props.callbackCloseModal]);

    function handleSelect(arg) {
        const convertedDateStart = formatDate(arg.startStr);
        const convertedDateEnd = formatDate(arg.endStr);
        setSelectedDay(convertedDateStart);
        setSelectedDayEnd(convertedDateEnd);

        if (selectedCalendarSection) {
            setSelectedSection(selectedCalendarSection);
        }
        if (selectedCalendarClinic) {
            setSelectedClinic(selectedCalendarClinic);
        }
        if (selectedCalendarDoctor) {
            setSelectedDoctor(selectedCalendarDoctor);
        }

        if (props.external) {
            props.callbackSelectedDateStart(convertedDateStart);
            props.callbackSelectedDateEnd(convertedDateEnd);
        }
        toggle();
    }

    useEffect(() => {
        if (selectedDayEnd !== "" && selectedDay !== "" && !isEdit) {
            checkOverlap({start: selectedDay, end: selectedDayEnd});
        }
    }, [selectedDay, selectedDayEnd]);

    function handleChangeInput(e) {
        if (e.target.name === "fullName") {
            setFullName(e.target.value);
        } else if (e.target.name === "phoneNumber") {
            setPhoneNumber(e.target.value);
        }
    }

    function onChangeSection(event) {
        setSelectedSection(event);
        setSelectedClinic(null);
        setSelectedDoctor(null);
    }

    function onChangeClinic(event) {
        setSelectedClinic(event);
        setSelectedDoctor(null);
    }

    function onChangeDoctor(event) {
        setSelectedDoctor(event);
    }

    function onChangeCalendarSection(event) {
        setSelectedCalendarSection(event);
        setSelectedCalendarClinic(null);
        setSelectedCalendarDoctor(null);
        setEvents([]);
    }

    function onChangeCalendarClinic(event) {
        setSelectedCalendarClinic(event);
        setSelectedCalendarDoctor(null);
        setEvents([]);
    }

    function onChangeCalendarDoctor(event) {
        setEvents([]);
        setSelectedCalendarDoctor(event);
        if (props.callbackSelectedDoctor) {
            props.callbackSelectedDoctor(event ? event.value : null);
        }
    }

    useEffect(() => {
        if (!roleDoctor) {
            getWithoutToken(process.env.REACT_APP_API_URL + "/userGroup/findHighAuthUsersWithDepartment").then(response => {
                if (response.status === 200) {
                    const sectionOptions = response.data.length > 0 && response.data.map(item => ({
                        label: item.section,
                        value: item
                    }));
                    setSectionOptions(sectionOptions);
                }
            });
        }
    }, [roleDoctor]);

    useEffect(() => {
        if (selectedCalendarSection) {
            setSelectedSection(selectedCalendarSection);
        }
        if (selectedCalendarClinic) {
            setSelectedClinic(selectedCalendarClinic);
        }
        if (selectedCalendarDoctor) {
            setSelectedDoctor(selectedCalendarDoctor);
        }
    }, [selectedCalendarSection, selectedCalendarClinic, selectedCalendarDoctor])

    function saveAppointment() {
        if (isEdit) {
            const req = {
                id: eventDetail.id,
                description: eventDetail.description,
                patient: eventDetail.patient,
                appointmentUser: eventDetail.appointmentUser,
                appointmentDate: selectedDay,
            };
            post(url.ADD_NEW_PATIENT_APPOINTMENT, req).then(response => {
                if (response.status === 200) {
                    const mapped = {
                        id: response.data.id,
                        title: response.data.patient.fullName,
                        fullName: response.data.patient.fullName,
                        phoneNumber: response.data.patient && response.data.patient.phoneNumber || "",
                        patient: response.data.patient,
                        appointmentUser: response.data.appointmentUser,
                        start: new Date(response.data.appointmentDate),
                        end: new Date(response.data.appointmentEndDate),
                        className: apptTypeClass["WAITING"]
                    };
                    const newMapped = events.map(appt => appt.id && appt.id.toString() === response.data.id.toString() ? mapped : appt);
                    setEvents(newMapped);
                }
            });
        } else {
            const req = {
                secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
                fullName: fullName,
                phoneNumber: phoneNumber,
                appointmentDate: selectedDay,
                appointmentUserId: selectedDoctor && selectedDoctor.value
            };
            post(url.SAVE_WITH_APPT, req).then(response => {
                if (response.status === 200) {
                    const mapped = {
                        id: response.data.id,
                        title: response.data.patient.fullName,
                        fullName: response.data.patient.fullName,
                        phoneNumber: response.data.patient && response.data.patient.phoneNumber || "",
                        patient: response.data.patient,
                        appointmentUser: response.data.appointmentUser,
                        start: new Date(response.data.appointmentDate),
                        end: new Date(response.data.appointmentEndDate),
                        className: apptTypeClass["WAITING"]
                    };
                    setEvents([...events, mapped]);
                }
            });
        }
        toggle();
    }

    function getCalendar() {
        const req = Object.assign({
            ...patientAppointmentBody,
            appointmentUser: selectedCalendarDoctor.value
        });
        const doctorId = props.external && authUser && authUser.role && authUser.role.name === "ROLE_DOCTOR" && !userAssistant ? authUser.id : selectedCalendarDoctor && selectedCalendarDoctor.value;
        dispatch(getUserWorkScheduleById(doctorId));
        post(url.GET_PATIENT_APPOINTMENT_CRITERIA, req).then(response => setPatientAppointments(response.data.content));
        get(`${url.AVAILABILTY_API_BY_ID}/${selectedCalendarDoctor.value}`).then(response => setUserAvailability(response.data));
    }

    useEffect(() => {
        if (selectedCalendarDoctor && selectedCalendarDoctor.value) {
            getCalendar();
        }
    }, [selectedCalendarDoctor])

    const {schedule} = useSelector(state => ({
        schedule: state.UserWorkSchedule.userWorkSchedules
    }));

    useEffect(() => {
        if (schedule && Object.entries(schedule).length > 0 && selectedCalendarDoctor) {
            setUserWorkSchedule(schedule);
            if (schedule.workDays !== undefined && schedule.workDays.length > 0) {
                const days = schedule.workDays && workDayOptions.filter((option) => !schedule.workDays.some((day) => day === option.name));
                const offDays = days.map(item => item.value);
                setHiddenDays(offDays);
            } else {
                setHiddenDays([]);
            }
            if (schedule.breakTimeStart !== undefined && schedule.breakTimeEnd !== undefined) {
                const disObject = {start: schedule.breakTimeStart, end: schedule.breakTimeEnd};
                setDisableTimes(disObject);
            }

            setSlotMinTime(schedule.workTimeStart);
            setSlotMaxTime(schedule.workTimeEnd);

            if (schedule.period) {
                const converted = convertMinutesToTime(schedule.period);
                setSlotDuration(converted);
            }
            if (schedule.workDays) {
                const days = workDayOptions && workDayOptions.filter((option) => schedule.workDays.some((day) => day === option.name));
                setSelectedWorkdays(days);
            }
        } else if (!schedule || !selectedCalendarDoctor) {
            setUserWorkSchedule({});
            setDisableTimes({});
            setHiddenDays([]);
            setSlotMinTime("09:00:00");
            setSlotMaxTime("20:00:00");
            setSlotDuration("00:30");
            setSelectedWorkdays([]);
        }
    }, [schedule, selectedCalendarDoctor]);

    const toggleAppt = () => setIsApptSettingsOpen(!apptSettingsOpen);

    function handleSelectedWorkdayOptions(option) {
        const days = option.map((item) => item.name);
        setSelectedWorkdays(option);
        setUserWorkSchedule({
            ...userWorkSchedule,
            ["workDays"]: days
        });
    }

    const handleChangeSettings = (e) => {
        setUserWorkSchedule({
            ...userWorkSchedule,
            [e.target.name]: e.target.value
        });
    };

    function handleChangeStart(e) {
        const convertedDate = formatDate(e.target.value);
        setSelectedDay(convertedDate);
    }

    function handleChangeEnd(e) {
        const convertedDate = formatDate(e.target.value);
        setSelectedDayEnd(convertedDate);
    }

    function handleSaveChanges() {
        props.saveChanges(userWorkSchedule);
    }

    return (
        <div className={!(props.external || props.dashboard) ? "page-content" : ""}>
            <Card>
                <CardBody>
                    <Row>
                        <div className="custom-accordion">
                            <Link
                                className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
                                onClick={toggleAppt}
                                to="#"
                            >
                                <i className="mdi mdi-calendar-account font-size-20 text-secondary"></i>{" "}
                                {props.t("Calendar Settings")}
                                <i
                                    className={
                                        apptSettingsOpen
                                            ? "mdi mdi-chevron-up accor-down-icon"
                                            : "mdi mdi-chevron-down accor-down-icon"
                                    }
                                />
                            </Link>
                            <Collapse isOpen={apptSettingsOpen}>
                                <div className="card border-1 shadow-none mb-0">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <Label
                                                            className="form-label">{props.t("Work Time Start")}</Label>
                                                        <Input
                                                            name="workTimeStart"
                                                            type="time"
                                                            onChange={handleChangeSettings}
                                                            value={userWorkSchedule.workTimeStart || ""}
                                                            disabled={!props.external || userAssistant}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Label
                                                            className="form-label">{props.t("Work Time End")}</Label>
                                                        <Input
                                                            name="workTimeEnd"
                                                            type="time"
                                                            onChange={handleChangeSettings}
                                                            value={userWorkSchedule.workTimeEnd || ""}
                                                            disabled={!props.external || userAssistant}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Label
                                                            className="form-label">{props.t("Break Time Start")}</Label>
                                                        <Input
                                                            name="breakTimeStart"
                                                            type="time"
                                                            onChange={handleChangeSettings}
                                                            value={userWorkSchedule.breakTimeStart || ""}
                                                            disabled={!props.external || userAssistant}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Label
                                                            className="form-label">{props.t("Break Time End")}</Label>
                                                        <Input
                                                            name="breakTimeEnd"
                                                            type="time"
                                                            onChange={handleChangeSettings}
                                                            value={userWorkSchedule.breakTimeEnd || ""}
                                                            disabled={!props.external || userAssistant}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <Label
                                                            className="form-label">{props.t("Timeline Period (Minutes)")}</Label>
                                                        <Input
                                                            name="period"
                                                            type="number"
                                                            onChange={handleChangeSettings}
                                                            value={userWorkSchedule.period || ""}
                                                            disabled={!props.external || userAssistant}
                                                        />
                                                    </div>
                                                    <div className="col-md-9">
                                                        <Label
                                                            className="form-label">{props.t("Work Days")}</Label>
                                                        <Select
                                                            value={selectedWorkdays}
                                                            isMulti={true}
                                                            onChange={(event) => {
                                                                handleSelectedWorkdayOptions(event);
                                                            }}
                                                            placeholder={props.t("Select")}
                                                            options={workDayOptions}
                                                            className="bs-select"
                                                            isDisabled={!props.external || userAssistant}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {props.external && !userAssistant && (
                                                        <div className="mt-4 text-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-success"
                                                                onClick={handleSaveChanges}
                                                            >
                                                                {props.t("Save Settings")}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Collapse>
                        </div>
                    </Row>
                    {props.external && doctorOptions.length > 1 && (
                        <Card className="card border-1 shadow-none mb-0">
                            <Row className="p-2">
                                <div className="text-start">
                                    <div className="row mt-3">
                                        <div className="col mt-1">
                                            <Label
                                                className="form-label">{props.t("Please select specialist to see events and settings")}</Label>
                                        </div>
                                        <div className="col">
                                            <Select
                                                value={selectedCalendarDoctor}
                                                onChange={(event) => onChangeCalendarDoctor(event)}
                                                options={doctorOptions}
                                                className="bs-select"
                                                isClearable={true}
                                            />
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                            </Row>
                        </Card>
                    )}
                    <Row>
                        <Card className="mt-3">
                            <WarningModal
                                show={warningModal}
                                onApproveClick={() => setWarningModal(false)}
                                onCloseClick={() => setWarningModal(false)}
                                title={props.t("Warning")}
                                message={props.t("Your select contains unselectable dates.Please select another date and time")}
                            />
                            <Row>
                                {!props.external && (
                                    <CardBody className="col-lg-3 mt-3">
                                        <Col className="justify-content-start">
                                            <div className="mt-5">
                                                <h5 className="text-center">{props.t("Event Types Info")}</h5>
                                                <ul className="ps-1">
                                                    <li className="text-wrap mb-3">
                              <span
                                  className={apptStatusActionClass["LEAVE"]}>{props.t("Off Days")}</span>
                                                    </li>
                                                    <li className="text-wrap mb-3">
                              <span
                                  className={apptStatusActionClass["APPROVED"]}>{props.t("Approved Appointments")}</span>
                                                    </li>
                                                    <li className="text-wrap mb-3">
                              <span
                                  className={apptStatusActionClass["COMPLETED"]}>{props.t("Completed Appointments")}</span>
                                                    </li>
                                                    <li className="text-wrap mb-3">
                              <span
                                  className={apptStatusActionClass["WAITING"]}>{props.t("Waiting Appointments")}</span>
                                                    </li>
                                                    <li className="text-wrap mb-3">
                              <span
                                  className={apptStatusActionClass["CANCELED"]}>{props.t("Canceled Appointments")}</span>
                                                    </li>
                                                    <li className="text-wrap mb-3">
                              <span
                                  className={apptStatusActionClass["REJECTED"]}>{props.t("Rejected Appointments")}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="mt-5 ">
                                                <div className="mb-2">
                                                    <Label className="form-label">{props.t("Section")}</Label>
                                                    <Select
                                                        value={selectedCalendarSection}
                                                        onChange={(event) => onChangeCalendarSection(event)}
                                                        options={sectionOptions}
                                                        className="bs-select"
                                                        isClearable={true}
                                                        isDisabled={props.dashboard}
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <Label className="form-label">{props.t("Clinic")}</Label>
                                                    <Select
                                                        value={selectedCalendarClinic}
                                                        onChange={(event) => onChangeCalendarClinic(event)}
                                                        options={clinicOptions}
                                                        className="bs-select"
                                                        isClearable={true}
                                                        isDisabled={props.dashboard}
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <Label className="form-label">{props.t("Specialist")}</Label>
                                                    <Select
                                                        value={selectedCalendarDoctor}
                                                        onChange={(event) => onChangeCalendarDoctor(event)}
                                                        options={doctorOptions}
                                                        className="bs-select"
                                                        isClearable={true}
                                                    />
                                                </div>
                                                <div className="mb-2 text-center">
                                                    <button
                                                        id="save"
                                                        type="submit"
                                                        className="btn btn-outline-success"
                                                        onClick={getCalendar}
                                                    >
                                                        {props.t("Get Calendar Events")}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    </CardBody>
                                )}
                                <CardBody className={props.external ? "col-lg-12 mt-3" : "col-lg-9 mt-3"}>
                                    <FullCalendar
                                        plugins={[
                                            BootstrapTheme,
                                            dayGridPlugin,
                                            interactionPlugin,
                                            timegridPlugin
                                        ]}
                                        themeSystem="bootstrap"
                                        initialView="timeGridWeek"
                                        slotDuration={slotDuration}
                                        handleWindowResize={true}
                                        expandRows={true}
                                        displayEventTime={true}
                                        displayEventEnd={true}
                                        eventTextColor="black"
                                        headerToolbar={{
                                            left: "prev,next today",
                                            center: "title",
                                            right: "dayGridMonth,timeGridWeek,timeGridDay"
                                        }}
                                        buttonText={{
                                            today: props.t("Today"),
                                            month: props.t("Month"),
                                            week: props.t("Week"),
                                            day: props.t("Day")
                                        }}
                                        selectConstraint={disableTimes}
                                        allDaySlot={false}
                                        slotMinTime={slotMinTime || "07:30"}
                                        slotMaxTime={slotMaxTime || "22:00"}
                                        //  dayHeaderFormat={{ weekday: "long", month: "numeric", day: "numeric", omitCommas: false }}
                                        locales="allLocales"
                                        locale={locale || "en"}
                                        firstDay={1} //Pazartesi manasında
                                        hiddenDays={hiddenDays}// hide Tuesdays and Thursdays
                                        weekends={true}
                                        events={events}
                                        eventResizableFromStart={true}
                                        editable={false}
                                        droppable={false}
                                        selectable={true}
                                        //allDayContent={}
                                        eventClick={handleEventClick}
                                        // eventAllow={selectAllow}
                                        select={handleSelect}
                                        selectAllow={checkOverlap}
                                        eventOverlap={false}
                                    />
                                </CardBody>
                            </Row>
                        </Card>
                    </Row>
                    <Modal isOpen={modal} className={props.className}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {isEdit ? props.t("Event Detail") : props.t("New Event")}
                        </ModalHeader>
                        <ModalBody>
                            {props.external ? (
                                props.eventComponent
                            ) : (
                                <Row className="m-2">
                                    <Col className="col-12">
                                        <div className="mb-2">
                                            <Label className="form-label">{props.t("Name")}</Label>
                                            <Input
                                                id="fullName"
                                                name="fullName"
                                                type="text"
                                                onChange={handleChangeInput}
                                                value={fullName || ""}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <Label className="form-label">{props.t("Phone")}</Label>
                                            <Input
                                                id="phoneNum"
                                                name="phoneNumber"
                                                type="text"
                                                onChange={handleChangeInput}
                                                value={phoneNumber || ""}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <Label
                                                className="form-label">{props.t("Section")}</Label>
                                            <Select
                                                value={selectedSection}
                                                onChange={(event) => onChangeSection(event)}
                                                options={sectionOptions}
                                                className="bs-select"
                                                isClearable={true}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <Label
                                                className="form-label">{props.t("Clinic")}</Label>
                                            <Select
                                                value={selectedClinic}
                                                onChange={(event) => onChangeClinic(event)}
                                                options={clinicOptions}
                                                className="bs-select"
                                                isClearable={true}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <Label
                                                className="form-label">{props.t("Specialist")}</Label>
                                            <Select
                                                value={selectedDoctor}
                                                onChange={(event) => onChangeDoctor(event)}
                                                options={doctorOptions}
                                                className="bs-select"
                                                isClearable={true}
                                            />
                                        </div>
                                        <Col className="col-12 mb-3">
                                            <Label className="form-label">{props.t("Start")}</Label>
                                            <Input
                                                name="start"
                                                type="datetime-local"
                                                onChange={handleChangeStart}
                                                value={selectedDay || ""}
                                            />
                                        </Col>
                                        <Col className="col-12 mb-3">
                                            <Label className="form-label">{props.t("End")}</Label>
                                            <Input
                                                name="end"
                                                type="datetime-local"
                                                onChange={handleChangeEnd}
                                                value={selectedDayEnd || ""}
                                            />
                                        </Col>
                                    </Col>
                                    <Row className="m-2">
                                        <Col>
                                            <div className="text-end mt-2">
                                                <button
                                                    id="save"
                                                    type="submit"
                                                    className="btn btn-success"
                                                    onClick={() => saveAppointment()}
                                                >
                                                    {props.t("Save")}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Row>
                            )}
                        </ModalBody>
                    </Modal>
                </CardBody>
            </Card>
        </div>
    );
};

Calendar.propTypes = {
    t: PropTypes.any,
    events: PropTypes.array,
    className: PropTypes.string,
    slotMinTime: PropTypes.string,
    slotMaxTime: PropTypes.string,
    slotDuration: PropTypes.string,
    external: PropTypes.bool,
    callbackFunction: PropTypes.func,
    disableTimes: PropTypes.object,
    calendarEventCallback: PropTypes.func,
    eventComponent: PropTypes.any,
    callbackSelectedDateStart: PropTypes.func,
    callbackSelectedDateEnd: PropTypes.func,
    callbackCloseModal: PropTypes.bool,
    callbackEventClick: PropTypes.func,
    saveChanges: PropTypes.func,
    dashboard: PropTypes.bool,
    callbackSelectedDoctor: PropTypes.func
};

export default withTranslation()(Calendar);
