import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import withRouter from "../../hooks/withRouter"
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { getReferences as onGetReferences } from "store/reference/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import { withTranslation } from "react-i18next";
import CustomDataTable from "../../components/Common/CustomDataTable";
import LeadDetail from "../Leads/LeadDetail";
import { getSegments as onGetSegments } from "../../store/segment/actions";
import { getCategories as onGetCategories } from "../../store/category/actions";
import { getResources as onGetResources } from "../../store/resource/actions";
import { userCriteria } from "../../store/users/actions";
import WarningModal from "../../components/Common/WarningModal";
import { deleteReminder, getReminderCriteria, updateReminder } from "../../store/reminder/actions";
import Select from "react-select";
import { DateToString } from "../../common/commonFunctions";
import useAuth from "../../hooks/useAuth";

const Reminder = props => {
  const { ntf } = useParams();

  const { authUser } = useAuth();
  const roleUser = authUser && authUser.role && authUser.role.name === "ROLE_USER";


  //meta title
  document.title = `CRM | ${props.t("Reminders")}`;

  const dispatch = useDispatch();
  const [lead, setLead] = useState({});
  const [resource, setResource] = useState({});
  const [segment, setSegment] = useState({});
  const [category, setCategory] = useState({});
  const [salesRepresentative, setSalesRepresentative] = useState({});
  const [reference, setReference] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selecteds, setSelecteds] = useState([]);
  const [reminderResponse, setReminderResponse] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterCreateDateStart, setFilterCreateDateStart] = useState(DateToString(new Date(Date.now()), "YYYY-MM-DD", "-"));
  const [filterCreateDateEnd, setFilterCreateDateEnd] = useState(DateToString(new Date(Date.now()), "YYYY-MM-DD", "-"));
  const [selectedUser, setSelectedUser] = useState();
  const [completed, setCompleted] = useState({ label: `${props.t("Not Completed")}`, value: false });

  function handleFilterCreateDateStart(e) {
    setFilterCreateDateStart(e.target.value);
  }

  function handleFilterCreateDateEnd(e) {
    setFilterCreateDateEnd(e.target.value);
  }

  function handleFilterReminderUser(e) {
    setSelectedUser(e);
  }

  const handleClearFilterInputs = () => {
    setFilterCreateDateStart("");
    setFilterCreateDateEnd("");
    setSelectedUser();
  };

  const initialBody = !roleUser ? {
    size: 1000,
    page: 0,
    isCompleted: false,
    reminderDateStart: filterCreateDateStart,
    reminderDateEnd: filterCreateDateEnd
  } : {
    size: 1000,
    page: 0,
    reminderUser: authUser.id,
    isCompleted: false,
    reminderDateStart: filterCreateDateStart,
    reminderDateEnd: filterCreateDateEnd
  };

  let [body, setBody] = useState(initialBody);

  useEffect(() => {
    dispatch(getReminderCriteria(body));
  }, []);

  const handleOpenModal = () => setIsModalOpen(!isModalOpen);

  // Reminder Column
  const reminderColumns = useMemo(
    () => [
      {
        dataField: "reminderDate",
        text: `${props.t("Reminder Date")}`,
        sort: true
      },
      {
        dataField: "lead.fullName",
        text: `${props.t("Lead")}`,
        sort: true
      },
      {
        dataField: "reminderUser.fullName",
        text: `${props.t("User")}`,
        sort: true
      },
      {
        dataField: "description",
        text: `${props.t("Description")}`,
        sort: true
      },
      {
        dataField: "completed",
        text: `${props.t("Status")}`,
        sort: true
      }
    ],
    []
  );

  const { reminders } = useSelector(state => ({
    reminders: state.Reminder.reminders
  }));

  const handleFilteredReminderData = () => {
    if (filterCreateDateStart !== "") {
      body = Object.assign(body, { reminderDateStart: filterCreateDateStart });
    }else{
      delete body.reminderDateStart
    }
    if (filterCreateDateEnd !== "") {
      body = Object.assign(body, { reminderDateEnd: filterCreateDateEnd });
    }else{
      delete body.reminderDateEnd
    }
    if (!roleUser) {
      if (selectedUser && selectedUser.value) {
        body = Object.assign(body, { reminderUser: selectedUser.value.id });
      } else {
        delete body.reminderUser;
      }
    }
    if (completed) {
      if (completed.value !== null) {
        body = Object.assign(body, { isCompleted: completed.value });
      }
    } else {
      delete body.isCompleted;
    }
    dispatch(getReminderCriteria(body));
  };

  useEffect(() => {
    const mapped = [];
    reminders.forEach(reminder => {
      if (reminder.completed) {
        mapped.push(Object.assign(reminder, {
          completed: <span>{props.t("Completed")} <i className="bx bx-check text-success font-size-22" /></span>
        }));
      } else {
        mapped.push(Object.assign(reminder, {
          completed: <span>{props.t("Not Completed")} <i className="bx bx-x text-danger font-size-22" /></span>
        }));
      }
    });
    setReminderResponse(mapped);
  }, [dispatch, reminders]);

  const { categories } = useSelector(state => ({
    categories: state.categories.categories
  }));

  const { segments } = useSelector(state => ({
    segments: state.segments.segments
  }));

  const { resources } = useSelector(state => ({
    resources: state.resources.resources
  }));

  const { users } = useSelector(state => ({
    users: state.users.users
  }));

  const { references } = useSelector(state => ({
    references: state.references.references
  }));

  const resourceOptions = [];
  resources.map((item) => {
    resourceOptions.push({ label: item.name, value: item });
  });

  const segmentOptions = [];
  segments.map((item) => {
    segmentOptions.push({ label: item.name, value: item });
  });

  const categoryOptions = [];
  categories.map((item) => {
    categoryOptions.push({ label: item.name, value: item });
  });

  const userOptions = [];
  users.map((item) => {
    userOptions.push({ label: item.fullName, value: item });
  });

  const referenceOptions = [];
  references.map((item) => {
    referenceOptions.push({ label: item.name, value: item });
  });

  useEffect(() => {
    dispatch(onGetSegments());
  }, []);

  useEffect(() => {
    dispatch(onGetCategories());
  }, []);

  useEffect(() => {
    dispatch(onGetResources());
  }, []);

  useEffect(() => {
    const userReq = Object.assign({ notRole: "ROLE_MODERATOR" });
    dispatch(userCriteria(userReq));
  }, []);

  useEffect(() => {
    dispatch(onGetReferences());
  }, []);

  const handleRowClick = reminder => {
    const lead = reminder.lead;
    setLead({
      id: lead.id,
      firstName: lead.firstName,
      lastName: lead.lastName,
      phoneNumber: lead.phoneNumber,
      email: lead.email,
      note: lead.note,
      cityName: lead.cityName,
      birthday: lead.birthday,
      salesRepresentative: lead.salesRepresentative,
      createDate: lead.createDate,
      reference: lead.reference,
      segment: lead.segment,
      category: lead.category,
      resource: lead.resource
    });

    lead.reference ? setReference({ label: lead.reference.name, value: lead.reference }) : null;
    lead.category ? setCategory({ label: lead.category.name, value: lead.category }) : null;
    lead.resource ? setResource({ label: lead.resource.name, value: lead.resource }) : null;
    lead.segment ? setSegment({ label: lead.segment.name, value: lead.segment }) : null;
    lead.salesRepresentative ? setSalesRepresentative({
      label: lead.salesRepresentative.fullName,
      value: lead.salesRepresentative
    }) : null;

    handleOpenModal();
  };

  function handleSelectedRows(selectedList) {
    if (selectedList.length > 0) {
      setSelecteds(selectedList);
      setShowOptions(true);
    } else {
      setSelecteds([]);
      setShowOptions(false);
    }
  }

  const handleRefresh = () => {
    dispatch(getReminderCriteria(body));
  };

  const handleDelete = () => {
    setDeleteModal(false);
    selecteds.forEach(remind => dispatch(deleteReminder(remind.id)));
    setSelecteds([]);
    setShowOptions(false);
  };

  const handleComplete = () => {
    selecteds.forEach(remind => {
      const reminder = Object.assign(remind, { completed: true });
      dispatch(updateReminder(reminder));
    });
    setSelecteds([]);
    setShowOptions(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <WarningModal
            id="deleteModal"
            show={deleteModal}
            onApproveClick={handleDelete}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal isOpen={isModalOpen} toggle={handleOpenModal} centered={true} size="xl" backdrop="static">
            <ModalHeader toggle={handleOpenModal} tag="h4"></ModalHeader>
            <ModalBody>
              {lead ? (
                <LeadDetail
                  lead={lead}
                  representOptions={userOptions}
                  resourceOptions={resourceOptions}
                  segmentOptions={segmentOptions}
                  categoryOptions={categoryOptions}
                  referenceOptions={referenceOptions}
                />
              ) : null}
            </ModalBody>
          </Modal>
          <Breadcrumbs title={props.t("Reminders")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="mb-3 col">
                      <Label className="form-label">{props.t("Reminder Date Start")}</Label>
                      <Input
                        id="create-date-start"
                        name="create-date-start"
                        type="date"
                        onChange={e => handleFilterCreateDateStart(e)}
                        value={filterCreateDateStart}
                      />
                    </div>
                    <div className="mb-3 col">
                      <Label className="form-label">{props.t("Reminder Date End")}</Label>
                      <Input
                        id="create-date-end"
                        name="create-date-end"
                        type="date"
                        onChange={e => handleFilterCreateDateEnd(e)}
                        value={filterCreateDateEnd}
                      />
                    </div>
                    <div className="mb-3 col">
                      <Label className="form-label">{props.t("Status")}</Label>
                      <Select
                        id="reminder-lead"
                        name="reminder-lead"
                        onChange={e => setCompleted(e)}
                        value={completed}
                        isClearable={true}
                        options={[{
                          label: `${props.t("Completed")}`,
                          value: true
                        }, { label: `${props.t("Not Completed")}`, value: false }]}
                      />
                    </div>
                    {!roleUser ? (
                      <div className="mb-3 col">
                        <Label className="form-label">{props.t("User")}</Label>
                        <Select
                          id="reminder-user"
                          name="reminderUser"
                          onChange={e => handleFilterReminderUser(e)}
                          value={selectedUser}
                          options={userOptions}
                          isClearable={true}
                        />
                      </div>) : ""}
                    <div className="mt-4 col">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded mb-0"
                        onClick={() => handleFilteredReminderData()}
                      >
                        <i className="mdi mdi-search-web me-1" />
                        {props.t("Filter")}
                      </Button>
                      <Button
                        type="button"
                        color="warning"
                        className="btn-rounded mb-0"
                        onClick={() => handleClearFilterInputs()}
                      >
                        <i className="mdi mdi-format-clear me-1" />
                        {props.t("Clear All Filters")}
                      </Button>
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded m-0"
                        onClick={handleRefresh}
                      >
                        <i className="mdi mdi-refresh" />
                      </Button>
                    </div>
                  </div>
                  <hr />

                  <Row>
                    {showOptions ? (
                      <>
                        <Button
                          id="complete"
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-2"
                          disabled={selecteds.length === 0}
                          onClick={() => handleComplete()}
                        >
                          <i className="mdi mdi-check me-1" />
                          {props.t("Complete")}
                        </Button>
                        <Button
                          id="delete"
                          type="button"
                          color="danger"
                          className="btn-rounded mb-2 me-2"
                          onClick={() => setDeleteModal(true)}
                        >
                          <i className="mdi mdi-delete me-1" />
                          {props.t("Delete")}
                        </Button>
                      </>
                    ) : ""}
                  </Row>
                  {reminderResponse.length > 0 ? (
                    <CustomDataTable
                      columns={reminderColumns}
                      data={reminderResponse}
                      sizePerPage={50}
                      selectRow={true}
                      search={false}
                      selectedEvent={handleSelectedRows}
                      rowEvents={handleRowClick}
                      customPagination={false}
                      className="custom-header-basic-css"
                    />) : (
                    <span>{props.t("You have no reminder")}</span>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
Reminder.propTypes = {
  reminders: PropTypes.array,
  getReminders: PropTypes.func
};

export default withRouter(withTranslation()(Reminder));
